import './App.css';
// import Footer from '../components/footer/Footer';
import Main from '../components/main';

function App() {
  return (
    <>

      <div className="App">
        {/* <Header /> */}
        <main className='main-container'>
          <Main />
        </main>
      </div>
    </>
  );
}

export default App;
