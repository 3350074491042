import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import '../App.css';
import './about.css';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import photo from '../../assets/DoddHeadshot2.jpg'; // Update with the correct path to your image
import bgImage from '../../assets/DoddBG1.jpg'; // Import background image


gsap.registerPlugin(ScrollTrigger);

function About() {

    useEffect(() => {
        // Ensure GSAP is targeting elements after they are in the DOM
        gsap.utils.toArray(".reveal-text").forEach((element) => {
            gsap.fromTo(element,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        toggleActions: "play none none none"
                    }
                }
            );
        });
    }, []);

    return (
        <>
            <div className="about-App" style={{
                '--bg-image': `url(${bgImage})` // Set background image as CSS variable
            }}>
                <Header />
                <div className="about-content">
                    <div className="about-row">
                        <div className="about-image">
                            <img src={photo} alt="Sarah Dodd" />
                        </div>
                        <div className="about-text">
                            <p className="reveal-text">
                                When you hire Dodd Communications, you get the name on the door. Dodd personally handles each client and the challenges they face. You benefit directly from Sarah&apos;s relationships, instincts, and strategy.
                            </p>
                        </div>
                    </div>
                    <div className="about-row about-full-text">
                        <h2>Sarah Dodd is a public affairs strategist and award-winning former journalist. </h2>
                        <p className="reveal-text">
                            In 2007, she started Dodd Communications, specializing in municipal lobbying, media strategy, crisis communications, litigation support and community relations. Dodd has been instrumental in securing land use entitlements for more than $10 Billion in Dallas real estate development. She has been proud to work on some of the most transformational projects in Dallas.
                        </p>
                        <p className="reveal-text">
                            Dodd routinely leads crisis strategy involving issues attracting the national and international media spotlight.
                        </p>
                        <p className="reveal-text">
                            As a journalist, Dodd covered local and national politics. While working for the CBS-owned television station in Dallas, Dodd traveled to Kuwait just weeks after 9/11 where she interviewed four-star general Tommy Franks. She was on the DMZ in South Korea as the nuclear threat from North Korea escalated, and in Guantanamo Bay, Cuba as suspected Taliban detainees were initially interviewed. In 2003, she spent the first month of the Iraq war as an embedded reporter with the 4th Infantry Division of the US. Army.
                        </p>
                        <p className="reveal-text">
                            During her decade-long career as a television news reporter and anchor, Dodd earned the coveted Edward R. Murrow Award for Excellence in Journalism, multiple EMMY nominations and the Iron Horse Award from the U.S. Army for Fair and Balanced news coverage.
                        </p>
                        <p className="reveal-text">
                            A graduate of Baylor University, Dodd has worked for ABC, NBC and CBS television stations, and her work has been featured on CNN and other network news programs. The book Media on the Frontline: Journalists Covering the War, dedicates a chapter to Sarah&#39;s experience in Iraq.
                        </p>
                        <p className="reveal-text">
                            Sarah was inducted into the Baylor University Hall of Fame as an Outstanding Young Alumni, selected as a German Marshall Fellow, honored as a Dallas Business Journal 40 Under 40, Graduate of Leadership Dallas and former LD Alumni Board Member, former Board member of the World Affairs Council and Uptown Dallas, Inc. Sarah has also been a regular guest lecturer at Southern Methodist University&#39;s School of Journalism.
                        </p>
                        <p className="reveal-text">
                            She currently serves as an Executive  Board Member of The Friends of the Katy Trail, on the Board of the  Center for Brain Health,  member of The Dallas Assembly and Charter 100.
                        </p>
                        <p className="reveal-text">
                            Dodd also serves on the Tate Lecture Series Board.
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default About;