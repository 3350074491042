import React, { useState, useEffect } from "react";
import "./Header.css";
import headerLOGO from '../../assets/dodd-communication-logo.svg'

const Header = ({ smoother }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        // Check if device has touch capabilities
        const checkTouchDevice = () => {
            const isTouch = (
                window.matchMedia("(hover: none)").matches ||
                window.matchMedia("(pointer: coarse)").matches ||
                'ontouchstart' in window ||
                navigator.maxTouchPoints > 0
            );
            setIsTouchDevice(isTouch);
            console.log(`Touch device in header: ${isTouch}`);
        };

        checkTouchDevice();
        window.addEventListener('resize', checkTouchDevice);

        return () => {
            window.removeEventListener('resize', checkTouchDevice);
        };
    }, []);

    const smoothScrollTo = (sectionId) => {
        if (window.location.pathname !== "/") {
            // Redirect to home page with hash
            window.location.href = `/${sectionId}`;
            return;
        }

        // Handle navigation based on device type
        if (isTouchDevice) {
            // Touch device behavior (mobile/tablet)
            if (menuOpen) {
                setMenuOpen(false);
                setTimeout(() => {
                    const target = document.querySelector(sectionId);
                    if (target) {
                        target.scrollIntoView({ behavior: "smooth" });
                    }
                }, 300);
            } else {
                const target = document.querySelector(sectionId);
                if (target) {
                    target.scrollIntoView({ behavior: "smooth" });
                }
            }
        } else {
            // Desktop behavior
            if (menuOpen) {
                setMenuOpen(false);
                setTimeout(() => {
                    const target = document.querySelector(sectionId);
                    if (target && smoother) {
                        smoother.scrollTo(target, true, "top");
                    }
                }, 300);
            } else if (smoother) {
                const target = document.querySelector(sectionId);
                if (target) {
                    smoother.scrollTo(target, true, "top");
                }
            }
        }
    };

    return (
        <header className="header">
            <div className="container">
                <div className="logo">
                    <a href="/">
                        <img src={headerLOGO} alt="DODD" className="logo-image" />
                    </a>
                </div>
                <button
                    className={`menu-toggle ${isTouchDevice ? 'show' : ''}`}
                    onClick={() => setMenuOpen(true)}
                >
                    <svg className="menu-icon" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="13" width="50" height="3" rx="3" fill="#c52470"></rect>
                        <rect y="23" width="50" height="3" rx="3" fill="#c52470"></rect>
                    </svg>
                </button>
                <nav className={`nav ${menuOpen ? "open" : ""} ${isTouchDevice ? 'touch-device' : ''}`}>
                    <button className="close-menu" onClick={() => setMenuOpen(false)}>
                        <svg className="close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                    <ul className="nav-list">
                        <li>
                            <button onClick={() => (window.location.href = "/about")} className="nav-link">About</button>
                        </li>
                        <li>
                            <button onClick={() => smoothScrollTo('#section-1')} className="nav-link">Projects</button>
                        </li>
                        <li>
                            <button onClick={() => smoothScrollTo('#contact')} className="nav-link">Contact</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;