import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./projectCar.css";
import project1 from "../assets/carousel/ProjectPhotosUpdate/Carpenter.jpg";
import project2 from "../assets/carousel/ProjectPhotosUpdate/Cresent.jpg";
import project3 from "../assets/carousel/ProjectPhotosUpdate/FourRivers.jpg";
import project4 from "../assets/carousel/ProjectPhotosUpdate/Granite.jpg";
import project5 from "../assets/carousel/ProjectPhotosUpdate/Hines.jpg";
import project6 from "../assets/carousel/ProjectPhotosUpdate/Hunt.jpg";
import project7 from "../assets/carousel/ProjectPhotosUpdate/KDC.jpg";
import project8 from "../assets/carousel/ProjectPhotosUpdate/Parkland.jpg";
import project9 from "../assets/carousel/ProjectPhotosUpdate/Ramrock.jpg";
import project10 from "../assets/carousel/ProjectPhotosUpdate/Trammell.jpg";
import project11 from "../assets/carousel/ProjectPhotosUpdate/Trulucks.jpg";
import project12 from "../assets/carousel/ProjectPhotosUpdate/Lincoln.jpg";

gsap.registerPlugin(ScrollTrigger);

const ProjectCarousel = () => {
    const [isMobile, setIsMobile] = useState(false);

    const ProjectImage = ({ src, title, subtitle }) => {
        return (
            <div className="project-image-wrapper">
                <img src={src} alt={title} className="project-image" />
                <div className="project-overlay">
                    <h3 className="project-title">{title}</h3>
                    <p className="project-subtitle">{subtitle}</p>
                </div>
            </div>
        );
    };

    const gridRef = useRef(null);

    useEffect(() => {
        console.log('ProjectCarousel mounted');

        // Log when the grid element is available
        if (gridRef.current) {
            console.log('Grid element mounted', {
                visibility: getComputedStyle(gridRef.current).visibility,
                display: getComputedStyle(gridRef.current).display,
                height: gridRef.current.offsetHeight,
                width: gridRef.current.offsetWidth
            });
        }

        // Check visibility periodically
        const visibilityCheck = setInterval(() => {
            if (gridRef.current) {
                const isVisible = gridRef.current.offsetHeight > 0 &&
                    gridRef.current.offsetWidth > 0 &&
                    getComputedStyle(gridRef.current).visibility !== 'hidden';
                console.log('Visibility check:', isVisible);
            }
        }, 2000);

        return () => {
            console.log('ProjectCarousel unmounted');
            clearInterval(visibilityCheck);
        };
    }, []);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth <= 1024);
        checkMobile(); // Initial check
        window.addEventListener("resize", checkMobile);
        return () => window.removeEventListener("resize", checkMobile);
    }, []);


    // useEffect(() => {
    //     const boxes = gridRef.current.querySelectorAll(".project-image-wrapper");

    //     // GSAP animation for ProjectImage contents
    //     boxes.forEach((box) => {
    //         const image = box.querySelector(".project-image");
    //         const overlay = box.querySelector(".project-overlay");
    //         const title = box.querySelector(".project-title");
    //         const subtitle = box.querySelector(".project-subtitle");

    //         gsap.fromTo(
    //             [image, overlay, title, subtitle],
    //             {
    //                 opacity: 0,
    //                 y: 50, // Start slightly below the viewport
    //             },
    //             {
    //                 opacity: 1,
    //                 y: 0, // Move to its original position
    //                 duration: 1,
    //                 ease: "power3.out",
    //                 stagger: 0.1, // Staggered animation for contents
    //                 scrollTrigger: {
    //                     trigger: box,
    //                     start: "top 80%", // Start when box enters 80% of viewport
    //                     toggleActions: "play none none none",
    //                 },
    //             }
    //         );
    //     });
    // }, []);

    return (
        <div className="carousel-wrapper">
            <div className="carousel-container">
                <div className="grid-Row" ref={gridRef}>
                    <div className="grid-column">
                        <ProjectImage
                            src={project1}
                            title={"Four Seasons Turtle Creek"}
                            subtitle={"Carpenter & Company"}
                        />
                        <ProjectImage
                            src={project2}
                            title={"Crescent Real Estate"}
                            subtitle={"2811 Maple Ave - Uptown Dallas"}
                        />
                        <ProjectImage
                            src={project3}
                            title={"Weir's Plaza Knox"}
                            subtitle={"Four Rivers Capital"}
                        />
                        <ProjectImage
                            src={project9}
                            title={"Preston Center"}
                            subtitle={"Ramrock"}
                        />
                    </div>
                    <div className="grid-column">
                        <ProjectImage
                            src={project4}
                            title={"Granite Properties"}
                            subtitle={"23 Springs Uptown Dallas"}
                        />
                        <ProjectImage
                            src={project5}
                            title={"Hines and Stockdale"}
                            subtitle={"Knox Promenade"}
                        />
                        <ProjectImage
                            src={project6}
                            title={"Hunt Realty Investments"}
                            subtitle={"Goldman Sachs Headquarters"}
                        />
                        <ProjectImage
                            src={project7}
                            title={"KDC"}
                            subtitle={"Klyde Warren Park"}
                        />
                    </div>
                    {!isMobile ? (
                        <div className="grid-column" id="comlumn3">
                            <ProjectImage
                                src={project8}
                                title={"Old Parkland Expansion"}
                                subtitle={"Crow Holdings"}
                            />
                            <ProjectImage
                                src={project10}
                                title={"Trammell Crow Company"}
                                subtitle={"Auberge Hotel, Knox District Mixed-Use"}
                            />
                            <ProjectImage
                                src={project11}
                                title={"Trammell Crow Company"}
                                subtitle={"Truluck's Dallas/ Office"}
                            />
                            <ProjectImage
                                src={project12}
                                title={"Lincoln Office"}
                                subtitle={"2500 Cedar Springs"}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="grid-column" id="mobile-column1">
                                <ProjectImage
                                    src={project8}
                                    title={"Old Parkland Expansion"}
                                    subtitle={"Crow Holdings"}
                                />
                                <ProjectImage
                                    src={project10}
                                    title={"Trammell Crow Company"}
                                    subtitle={"Auberge Hotel, Knox District Mixed-Use"}
                                />
                            </div>
                            <div className="grid-column" id="mobile-column2">
                                <ProjectImage
                                    src={project11}
                                    title={"Trammell Crow Company"}
                                    subtitle={"Truluck's Dallas/ Office"}
                                />
                                <ProjectImage
                                    src={project12}
                                    title={"Lincoln Office"}
                                    subtitle={"2500 Cedar Springs"}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectCarousel;