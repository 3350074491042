import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./info.css";
import ProjectCarousel from "../../projectCar"; // New component for the carousel


gsap.registerPlugin(ScrollTrigger);

const Info = () => {
    const [isMobile, setIsMobile] = useState(false);
    const servicesRef = useRef(null);
    const contentRef = useRef(null);
    const animationsSetupRef = useRef(false);


    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth <= 768);
        checkMobile();
        window.addEventListener("resize", checkMobile);

        return () => {
            window.removeEventListener("resize", checkMobile);
        };
    }, []);


    useEffect(() => {

        // Store the current ref value
        const currentContent = contentRef.current;
        if (!currentContent) return;

        // Set initial visibility to ensure content is visible during setup
        const sections = document.querySelectorAll(".snap-section");
        sections.forEach(section => {
            gsap.set(section, { opacity: 1, y: 0 });
        });

        // Kill existing ScrollTriggers
        ScrollTrigger.getAll().forEach(trigger => {
            if (trigger.vars.trigger && currentContent?.contains(trigger.vars.trigger)) {
                trigger.kill();
            }
        });

        // Setup animations with a slight delay to ensure DOM is ready
        const setupAnimations = () => {
            if (animationsSetupRef.current) return;
            animationsSetupRef.current = true;

            // Force a ScrollTrigger refresh before setting up new animations
            ScrollTrigger.refresh();

            // Add a small delay before setting up animations
            setTimeout(() => {
                sections.forEach((section) => {
                    gsap.fromTo(
                        section,
                        {
                            opacity: 0,
                            y: 75,
                            immediateRender: true
                        },
                        {
                            opacity: 1,
                            y: 0,
                            duration: 1,
                            ease: "power3.out",
                            scrollTrigger: {
                                trigger: section,
                                start: "top 80%",
                                end: "bottom 20%",
                                toggleActions: "play none none reverse",
                                scrub: false,
                                onEnter: () => console.log("Section entered view"),
                                onLeave: () => console.log("Section left view")
                            },
                        }
                    );
                });

                const titleChars = document.querySelectorAll(".HeroTitle .char");
                if (titleChars.length > 0) {
                    gsap.fromTo(
                        titleChars,
                        {
                            opacity: 0,
                            y: 50,
                            scale: 0.9,
                            immediateRender: true
                        },
                        {
                            opacity: 1,
                            y: 0,
                            scale: 1,
                            stagger: 0.02,
                            ease: "power3.out",
                            duration: 2,
                            scrollTrigger: {
                                trigger: ".HeroTitle",
                                start: "top 90%",
                                toggleActions: "play none none reverse",
                                markers: false
                            },
                        }
                    );
                }

                const subtitleWords = document.querySelectorAll(".HeroSubtitle .word");
                if (subtitleWords.length > 0) {
                    gsap.fromTo(
                        subtitleWords,
                        {
                            opacity: 0,
                            y: 30,
                            immediateRender: true
                        },
                        {
                            opacity: 1,
                            y: 0,
                            stagger: 0.03,
                            ease: "power2.out",
                            duration: isMobile ? 0.5 : 1,
                            scrollTrigger: {
                                trigger: ".HeroSubtitle",
                                start: "top 90%",
                                toggleActions: "play none none reverse",
                                markers: false
                            },
                        }
                    );
                }

                if (servicesRef.current) {
                    const items = servicesRef.current.querySelectorAll(".service-item");
                    items.forEach((item) => {
                        gsap.fromTo(
                            item,
                            {
                                opacity: 0,
                                y: 50,
                                scale: 0.9,
                                immediateRender: true
                            },
                            {
                                opacity: 1,
                                y: 0,
                                scale: 1,
                                duration: 0.8,
                                ease: "power3.out",
                                scrollTrigger: {
                                    trigger: item,
                                    start: "top 85%",
                                    toggleActions: "play none none reverse",
                                    markers: false
                                },
                            }
                        );
                    });
                }

                // Force refresh after animations are set up
                ScrollTrigger.refresh();
            }, 100);


        };

        // Run setup after a delay to ensure DOM is ready
        const initialSetupTimeout = setTimeout(() => {
            setupAnimations();
        }, 100);

        // Add a mutation observer to detect DOM changes
        const observer = new MutationObserver((mutations) => {
            animationsSetupRef.current = false;
            ScrollTrigger.refresh();
            setupAnimations();
        });

        observer.observe(currentContent, {
            childList: true,
            subtree: true
        });

        return () => {
            clearTimeout(initialSetupTimeout);
            observer.disconnect();
            ScrollTrigger.getAll().forEach(trigger => {
                if (trigger.vars.trigger && currentContent?.contains(trigger.vars.trigger)) {
                    trigger.kill();
                }
            });
            animationsSetupRef.current = false;
        };
    }, [isMobile]);



    return (
        <section className="info-container" id="home" ref={contentRef}>

            <section id="section-1" className="projects-section">
                <h2 className="projects-title">Notable Projects</h2>
                <ProjectCarousel />
            </section>

            <div className="line-container">
                <div id="lbr2" className="break-line"></div>
            </div>
            <section id="section-2" className="HeroTextSection snap-section">
                <div className="HeroText">
                    <p>
                        <strong>Dodd Communications: Right Outlook, Right Outcome</strong>
                    </p>
                    <p>
                        Dodd Communications is a round-the-clock advocate for our clients and the critical challenges they face.
                        We understand the intricacies of navigating public perception, and we know that for our clients,
                        there's only one option: success.
                    </p>
                    <p>
                        Dodd brings an insider's perspective on the media and local politics that is critical to her clients' success.
                    </p>
                    <p>
                        She has been involved in municipal government for almost two decades. First as a journalist building relationships
                        at every level of local politics, now as a lobbyist. Dodd's deep understanding and institutional knowledge of local government
                        paired with relationships built on years of trust equals results.
                    </p>
                </div>
            </section>
            <div id="lbr" className="line-container">
                <div id="lbr2" className="break-line"></div>
            </div>
            <section style={{ zIndex: 5, width: '100%' }}>
                <div id="section-3" className="advocate-content snap-section">
                    <h1 className="advocateTitle">
                        A True Advocate
                    </h1>
                    <p className="advocateSubtitle">
                        Dodd Communications doesn&apos;t just manage a crisis. Sarah Dodd&apos;s strategic, personal approach to public perception issues changes minds and solves problems that others can&apos;t. Dodd represents the top-tier of strategic communications and media counsel.
                    </p>
                    <button className="btn btn-primary" onClick={() => window.location.href = "/about"}>
                        MEET OUR FOUNDER
                    </button>
                </div>
                <div className="Service-attention-container snap-section ">
                    <div id="services" className="service-title-container">
                        <h1>SERVICES</h1>
                    </div>
                    <div className="line-container">
                        <div className="break-line"></div>
                    </div>
                </div>
                {/* <Services /> */}
                <section id="section-4" className="services-wrapper snap-section">
                    {/* <div className="message-development">
                        <p>
                            <strong style={{ color: '#c52470' }}>Message Development:</strong> Sarah Dodd is a purveyor of the truth. By understanding all sides of an issue,
                            using first-hand interviews, research, and subject matter expertise, she can identify the messages that resonate with key audiences.
                        </p>
                    </div> */}
                    <div ref={servicesRef} className="services-container">
                        {[
                            { title: "Public Affairs" },
                            { title: "Lobbying" },
                            { title: "Crisis Strategy" },
                            { title: "Community Relations" },
                        ].map((service, index) => (
                            <div className="service-item" key={index}>
                                <h3 className="service-title">{service.title}</h3>
                            </div>
                        ))}
                    </div>
                </section>
            </section>



        </section>
    );
};

export default Info;