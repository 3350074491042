import React from "react";
import "./contact.css";

const Contact = () => {
    return (
        <>

            {/* <div className="line-contact">
                <div className="break-contact"></div>
            </div> */}
            <section id="contact" className="GetInTouch">
                <div className="content-cantact">
                    <div className="contact-container">
                        <h2 className="contact-title">Get In Touch</h2>
                        <a
                            href="mailto:info@doddcommunications.coms"
                            className="contact-email"
                        >
                            info@doddcommunications.com
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;