import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import Video from "./sections/video/video";
import Info from "./sections/info/info";
import Contact from "./sections/contact/contact";
import Footer from "./footer/Footer";
import "./main.css";
import Header from "./header/Header";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Main = () => {
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [overlayVisible, setOverlayVisible] = useState(true);
    const smootherRef = useRef(null);

    useEffect(() => {
        // Check if device has touch capabilities
        const checkTouchDevice = () => {
            const isTouch = (
                window.matchMedia("(hover: none)").matches ||
                window.matchMedia("(pointer: coarse)").matches ||
                'ontouchstart' in window ||
                navigator.maxTouchPoints > 0
            );
            setIsTouchDevice(isTouch);
        };

        checkTouchDevice();
        window.addEventListener('resize', checkTouchDevice);

        // Reset any existing ScrollTrigger instances
        ScrollTrigger.getAll().forEach(st => st.kill());

        if (!isTouchDevice) {
            // Enable smooth scrolling for desktop
            smootherRef.current = ScrollSmoother.create({
                wrapper: "#smooth-wrapper",
                content: "#smooth-content",
                smooth: 1.5,
                effects: true,
                smoothTouch: 0.1,
                normalizeScroll: true,
            });
        } else {
            if (smootherRef.current) {
                smootherRef.current.kill();
                smootherRef.current = null;
            }

            // Reset all scroll-related styles
            document.body.style.overflow = "";
            document.body.style.height = "";

            const smoothWrapper = document.getElementById("smooth-wrapper");
            const smoothContent = document.getElementById("smooth-content");

            if (smoothWrapper) {
                smoothWrapper.style = "";
            }
            if (smoothContent) {
                smoothContent.style = "";
            }
        }

        // Force a ScrollTrigger refresh after layout changes
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 100);

        return () => {
            window.removeEventListener('resize', checkTouchDevice);
            if (smootherRef.current) {
                smootherRef.current.kill();
            }
            ScrollTrigger.getAll().forEach(st => st.kill());
        };
    }, [isTouchDevice]);

    const handleVideoStart = () => {
        if (smootherRef.current) {
            smootherRef.current.paused(true);
        }
    };

    const handleVideoEnd = () => {
        setTimeout(() => {
            const target = document.querySelector("#section-1");
            if (target) {
                if (smootherRef.current) {
                    smootherRef.current.scrollTo(target, true, "top");
                } else {
                    // For mobile devices, use window.scrollTo with offset calculation
                    console.log("Scrolling to target");
                    // Ensure we're starting from a stable scroll position
                    window.scrollTo(0, window.scrollY);

                    // Wait for any ongoing scroll animations to finish
                    setTimeout(() => {
                        const targetPosition = target.getBoundingClientRect().top + window.scrollY;
                        // Force a reflow to ensure accurate positioning
                        void document.body.offsetHeight;

                        window.scrollTo({
                            top: targetPosition - 100,
                            behavior: 'smooth'
                        });

                        // Backup scroll attempt in case the first one didn't complete
                        setTimeout(() => {
                            if (Math.abs(window.scrollY - targetPosition) > 10) {
                                window.scrollTo({
                                    top: targetPosition,
                                    behavior: 'auto'
                                });
                            }
                        }, 1000);
                    }, 200);
                }
            }
            setOverlayVisible(false);
        }, 500);
    };

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            setTimeout(() => {
                const target = document.querySelector(hash);
                if (target) {
                    if (smootherRef.current) {
                        smootherRef.current.scrollTo(target, true, "top");
                    } else {
                        target.scrollIntoView({ behavior: "smooth" });
                    }
                }
            }, 500);
        }
    }, []);

    return (
        <>
            <div className="sticky-header">
                <Header smoother={smootherRef.current} />
            </div>
            <div id="smooth-wrapper" style={{ position: isTouchDevice ? 'relative' : 'fixed' }}>
                <div id="smooth-content" style={{ position: isTouchDevice ? 'relative' : 'absolute' }}>
                    <main className="Main">
                        <Video onVideoStart={handleVideoStart} onVideoEnd={handleVideoEnd} />
                        <div className="wrapper">
                            <div className={`overlay ${overlayVisible ? "visible" : "hidden"}`}></div>
                            <Info />
                            <div className="contact-component-container">
                                <Contact />
                            </div>
                        </div>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
};

export default Main;