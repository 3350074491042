// src/components/sections/video/Video.js
import React, { useEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import gsap from "gsap";
import "./video.css";
import IntroVid from "../../../assets/DoddHomeDesktopFaster.mp4";
import MobileVid from '../../../assets/DoddHomeMobileFaster.mp4'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Video = ({ onVideoStart, onVideoEnd }) => {
    const videoRef = useRef(null);
    const hasEndedRef = useRef(false);
    // const scrollEnabledRef = useRef(false);
    const [videoSrc, setVideoSrc] = useState(IntroVid);


    useEffect(() => {
        const video = videoRef.current;

        // function to disable and enable scrolling
        const disableScrolling = () => {
            const smoother = ScrollSmoother.get();
            if (smoother) {
                smoother.paused(true); // Pause ScrollSmoother
            }
            document.body.style.overflow = "hidden"; // Disable body scrolling
            document.body.style.touchAction = "none"; // Prevent touch gestures
        };
        const enableScrolling = () => {
            const smoother = ScrollSmoother.get();
            if (smoother) {
                smoother.paused(false); // Resume ScrollSmoother
            }
            document.body.style.overflow = ""; // Enable body scrolling
            document.body.style.touchAction = ""; // Allow touch gestures
        };

        const preventDefaultTouch = (e) => {
            e.preventDefault();
        };

        // setting up video source for mobile
        const localIsMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;
        if (localIsMobile) {
            setVideoSrc(MobileVid);
        }


        const handleLoadedMetadata = () => {
            const hasWatchedVideo = sessionStorage.getItem("hasWatchedIntroVideo") === "true";

            if (hasWatchedVideo) {
                video.currentTime = video.duration || 0;
                video.pause();
                onVideoEnd();
            } else {
                // Disable scrolling for both mobile and desktop until video ends
                disableScrolling();
                // Add touch prevention only for mobile
                if (localIsMobile) {
                    document.addEventListener("touchmove", preventDefaultTouch, { passive: false });
                }
            }
        };

        // const handleScrollToResume = (e) => {
        //     if (!hasEndedRef.current && !localIsMobile) {
        //         e.preventDefault(); // Prevent default scroll behavior
        //         if (!scrollEnabledRef.current) {
        //             video.play(); // Resume video playback
        //             scrollEnabledRef.current = true; // Mark as resumed
        //             console.log("Video resumed by scroll");
        //         }
        //     }
        // };

        // const handleVideoProgress = () => {
        //     const currentTime = video.currentTime;

        //     const isNearPause = (time) =>
        //         pausePoints.some(
        //             (point) => Math.abs(point - time) < 0.1 && !pausedAtPoints.has(point)
        //         );

        //     if (isNearPause(currentTime)) {
        //         const pausePoint = pausePoints.find(
        //             (point) => Math.abs(point - currentTime) < 0.1
        //         );
        //         pausedAtPoints.add(pausePoint);
        //         video.pause();
        //         scrollEnabledRef.current = false; // Allow scroll to resume the video
        //         console.log(`Video paused at ${pausePoint}`);
        //     }
        // };

        const handleVideoEnd = () => {
            console.log("Video has ended");
            hasEndedRef.current = true;
            enableScrolling();
            sessionStorage.setItem("hasWatchedIntroVideo", "true");
            onVideoEnd();

            // Remove touchmove listener for mobile
            document.removeEventListener("touchmove", preventDefaultTouch);
        };

        // Attach common event listeners
        video.addEventListener("loadedmetadata", handleLoadedMetadata);
        video.addEventListener("ended", handleVideoEnd);


        return () => {
            video.removeEventListener("loadedmetadata", handleLoadedMetadata);
            video.removeEventListener("ended", handleVideoEnd);
            document.removeEventListener("touchmove", preventDefaultTouch);

            // Ensure scrolling is enabled when component unmounts
            enableScrolling();
        };
    }, [onVideoEnd]);

    return (
        <section className="IntroVideo">
            <div id='video-container' className="video-container">
                <video
                    ref={videoRef}
                    src={videoSrc}
                    playsInline
                    preload="true"
                    muted
                    autoPlay
                    className="video"
                    poster="../../../assets/intro-placeholder.jpg"
                ></video>
            </div>
        </section>
    );
};

export default Video;