import React from "react";
import "./Footer.css";
import footerLOGO from '../../assets/dodd-footer-logo.svg'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <img src={footerLOGO} alt="Dodd Logo" className="footer-logo-img" />
                </div>
                <div className="footer-section">
                    <h3>Home</h3>
                    <ul className="footer-links">
                        <li><a href="/#section-1" className="footer-link">Projects</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>About</h3>
                    <ul className="footer-links">
                        <li><a href="/about" className="footer-link">Sarah Dodd</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>Contact</h3>
                    <ul className="footer-links">
                        <li><a href="mailto:info@doddcommunications.com" className="footer-link">info@doddcommunications.com</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2025 Dodd Communications</p>
            </div>
        </footer>
    );
};

export default Footer;